/*div[ref=component].form-group label {
    width: 25%;
    text-align: right;
    padding-right: 5px;
}
div[ref=component].form-group div[ref=element] {
    width: 74%;
    display: inline-block;
}
div[ref=component].form-group div.formio-choices {
    width: 74%;
    display: inline-block;
}*/

.tooltip-inner {
  text-align: left !important;
}

.formio-component .form-control:focus {
  box-shadow: 0 0 0 2px rgb(137 110 177 / 20%) !important;
  color: #696969;
  border-color: #896eb1 !important;
  background-color: #fff;
  outline: 0;
}

.formio-component-button {
  display: inline-block;
}

.modal-footer > button {
  background-color: #896eb1 !important;
  border-color: #776bbe !important;
}

.modal-footer > button:hover {
  background-color: #6b60ab !important;
  border-color: #695ea7 !important;
}

.formio-component > button {
  background-color: #896eb1 !important;
  border-color: #776bbe !important;
}

.formio-component > button:hover {
  background-color: #6b60ab !important;
  border-color: #695ea7 !important;
}

.fieldset-body fieldset > legend {
  color: inherit;
  font-size: 1.2rem;
}

fieldset > legend {
  color: #2c86d1;
  font-size: 1.5rem;
  font-weight: 700 !important;
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
  border-color: #896eb1 !important;
}

.review-group h5 {
  font-weight: 700 !important;
}

.review-group .card-header {
  color: #2c86d1;
  font-size: 20px;
  font-weight: 700 !important;
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
  border-color: #896eb1 !important;
}

.fieldset-body fieldset > legend {
  color: inherit;
  font-size: 1.2rem;
}

.formio-hidden,
.formio-component-hidden,
div[ref="buttonMessageContainer"],
.formio-select-autocomplete-input {
  display: none !important;
}
.formio-component.formio-error-wrapper {
  background-color: #fff;
  padding: 0;
}
div[ref="messageContainer"] {
  text-align: right;
}
div[ref="messageContainer"].invalid-feedback {
  display: initial;
}
.formio-component.has-message .invalid-feedback {
  display: none;
}
.formio-component.has-message .form-control.is-invalid {
  border-color: #d3d5d7;
}
.formio-component.has-error .invalid-feedback {
  display: block;
}
.formio-component.has-error .form-control.is-invalid {
  border-color: #e06953;
}

.td-stacked {
  display: block;
  clear: both;
}

/* condition for screen size minimum of 797px */
@media (min-width: 797px) {
  .formio-component-fieldset .col-form-label {
    width: 33%;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
    vertical-align: top;
    cursor: auto;
  }
  .formio-component-fieldset div[ref^="react-"],
  .formio-component-fieldset div[ref="element"],
  .formio-component-fieldset .formio-choices,
  .formio-component-fieldset .form-radio.radio {
    width: 66%;
    display: inline-block;
  }
  .col-form-buttons-offset {
    width: 33%;
  }
  .form-check.checkbox {
    padding-left: calc(33% + 23px);
  }
}
.editgrid-actions {
  text-align: right;
}
.formio-component-editgrid > button {
  float: right;
  margin-top: 10px;
}
.formio-component-editgrid i.fa-refresh {
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  display: table;
}

.editgrid-actions > button {
  background-color: #896eb1 !important;
  border-color: #776bbe !important;
}

.editgrid-actions > button:hover {
  background-color: #6b60ab !important;
  border-color: #695ea7 !important;
}

.form-navigation {
  position: sticky;
  top: 125px;
  z-index: 998;
}

.disabled:not(.btn) {
  color: #848484;
  background-color: #eee !important;
}

.formio-component-select.has-error .choices[data-type*="select-one"]:after,
.formio-component-select.formio-error-wrapper
  .choices[data-type*="select-one"]:after {
  right: 31.5px;
}

.review-item {
  margin-bottom: 1rem;
}
.review-item-label {
  color: #5d5f63;
  font-weight: bold;
}
table.review-grid-view {
  width: 100%;
  border-radius: 6px;
}
table.review-grid-view tr {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
table.review-grid-view th,
table.review-grid-view td {
  padding: 7px;
}

.text-review {
  font-family: "Open Sans", Arial, sans-serif !important;
  color: #41464d !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #896eb1 !important;
  border-color: #896eb1 !important;
}
